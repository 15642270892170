import { FieldSchema } from '@backstage/plugin-scaffolder';
import { JSONSchema7 } from 'json-schema';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';
import { RepoUrlPickerState } from './types';

export function getRepoName(data: RepoUrlPickerState) {
  return data.repoName;
}

export function parseRepoPickerFormData(
  formData: string | undefined,
): RepoUrlPickerState {
  const host = '';
  const owner = '';
  const organization = '';
  const workspace = '';
  const project = '';
  let repoName = '';
  if (formData !== undefined) {
    repoName = formData;
  }
  return { host, owner, repoName, organization, workspace, project };
}

export function getRepoNameBasedOnTeamName(
  availableRepos: string[],
  team: string,
  state: RepoUrlPickerState,
) {
  if (availableRepos?.length === 0) {
    return 'none';
  }
  if (team !== undefined) {
    for (let i = 0; i < availableRepos.length; i++) {
      const result = team.replace('group:default/', '');
      const regex = new RegExp(result, 'gmu');
      if (`${availableRepos[i]}`.match(regex) !== null) {
        state.repoName = availableRepos[i];
        return availableRepos[i]; // return the matching repository name
      }
    }
  }
  return availableRepos[0]; // return the first element if no matching repository name is found
}

/**
 * @public
 * Utility function to convert zod return and UI options schemas to a
 * CustomFieldExtensionSchema with FieldExtensionComponentProps type inference
 */
export function makeFieldSchemaFromZod<
  TReturnSchema extends z.ZodType,
  TUiOptionsSchema extends z.ZodType = z.ZodType<any, any, {}>,
>(
  returnSchema: TReturnSchema,
  uiOptionsSchema?: TUiOptionsSchema,
): FieldSchema<
  TReturnSchema extends z.ZodType<any, any, infer IReturn> ? IReturn : never,
  TUiOptionsSchema extends z.ZodType<any, any, infer IUiOptions>
    ? IUiOptions
    : never
> {
  return {
    schema: {
      returnValue: zodToJsonSchema(returnSchema) as JSONSchema7,
      uiOptions: uiOptionsSchema
        ? (zodToJsonSchema(uiOptionsSchema) as JSONSchema7)
        : undefined,
    },
    type: null as any,
    uiOptionsType: null as any,
  };
}
