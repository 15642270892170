import React, { useMemo, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import nunjucks from 'nunjucks';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import _ from 'lodash';
import { additionalTemplateFilters } from '@internal/plugin-cheetah-common';
import { UIOptionsType } from '@rjsf/utils';

export interface PreviewValueFieldUiOptions extends UIOptionsType {
  inputField: string[] | string;
  template: string;
}

interface InputValue {
  [key: string]: any;
}

export const PreviewValueField = (
  props: FieldExtensionComponentProps<string, PreviewValueFieldUiOptions>,
): React.ReactElement | null => {
  const {
    uiSchema,
    rawErrors,
    schema: { title = 'Preview', description = '' },
    formData,
    idSchema,
    formContext,
    onChange,
  } = props;

  const formRecords: Record<string, any> = formContext.formData;

  const inputField = useMemo(
    () => uiSchema?.['ui:options']?.inputField ?? [],
    [uiSchema],
  );
  const template = useMemo(
    () => String(uiSchema?.['ui:options']?.template ?? '{{ input }}'),
    [uiSchema],
  );

  const inputValue = Array.isArray(inputField)
    ? inputField.reduce((acc, field, index) => {
        acc[`input${index}`] = _.get(formRecords, field, '');
        return acc;
      }, {} as InputValue)
    : _.find(formRecords, (_s, fieldName) => fieldName === inputField);

  const env = new nunjucks.Environment();

  for (const [filterName, filterFn] of Object.entries(
    additionalTemplateFilters,
  )) {
    env.addFilter(filterName, filterFn);
  }
  const renderedTemplate = nunjucks.compile(template, env);

  useEffect(() => {
    const output = Array.isArray(inputField)
      ? renderedTemplate.render(inputValue)
      : renderedTemplate.render({ input: inputValue });
    onChange(output);
  }, [inputValue, inputField, onChange, renderedTemplate]);

  return (
    <TextField
      id={idSchema?.$id}
      label={title}
      helperText={description}
      value={formData ?? ''}
      disabled
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
    />
  );
};
